import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import ContentImage from "../components/ContentImage"

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: true,
  title: 'Oops. The page you were looking for does not exist. You may have mistyped the address or the page may have been moved.',
  image: require('../assets/images/sleeping.png'),
  buttons: [
    {
    type: 'normal',
    text: 'Go to Homepage',
    link: '/',
    beacon: false
    }
  ]
}

function errorPage() {
  return (
    <>
      <Header />
      <main className="error-page initialized-header">
         <ContentImage content={ cta }  />
      </main>
      <Footer />
    </>
  )
}

export default errorPage
